//@import url("../css/line-awesome.min.css");

.big-title { 
    background: url('../images/texure.jpg'); 
    background-repeat: repeat-x; 
    background-position: 100% 100%; 
    color: transparent; 
    -webkit-font-smoothing: antialiased; 
    -webkit-background-clip: text; 
    -moz-background-clip: text; 
    background-clip: text; 
    -webkit-text-fill-color: transparent; 
}

.main-h1 {
    font-size: 4.209em;
}

.main-h2 {
    font-size: 3.157em;
}

.main-h3 {
    font-size: 2.369em;
}

.main-h5 {
    font-size: 1.333em;
}

.main-h1, .main-h2, .main-h3, .main-h4, .main-h5, .main-h6 {
    font-weight: 500;
    margin: 0;
    line-height: 1.2;
    color: #fff;
}



header#main-header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    text-align: center;
    z-index: 999;
    background: rgba(20,20,20,.5)!important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

img.logo { width: 160px;  }

.menu-main-menu-container{
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
}

@media (min-width: 992px) {
    .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .main-container-fluid {
        padding: 0 100px !important;
    }
    
}


.navbar-collapse {
    flex-grow: 1;
    align-items: center;
}

.navbar-toggler {
    color: rgba(0,0,0,.5);
    border-color: rgba(0,0,0,.1);
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.navbar-toggler-icon {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYAgMAAACdGdVrAAAACVBMVEUAAAD///////9zeKVjAAAAAnRSTlMAuLMp9oYAAAAVSURBVAjXY4CCrFVAsBJGkQpobwoAVYgWY5ERw8gAAAAASUVORK5CYII=) no-repeat scroll 50%;
}

ul.navbar-nav {
    display: flex;
    text-align: right;
    flex-direction: row;
    li.menu-item{
        margin-right: 18px;
        a{
            font-size: 22px;            
            padding: 0 0 0 10px;
            line-height: 70px;
            z-index: 9;
            text-decoration: none;
            color: #fff;
        }
        :hover {
            color: #6210cc;
        }
    }
}



#iq-favorites{
    padding-top: 10%;
    max-height: 100vh;
    height: 100%;
}
.main-content-contaner{    
    margin : 0 auto;
}
.iq-main-header{
    margin : 5% 0;
}

.cycle-title{
    color:#eee; 
    width: 28%;             
    text-align: center; 
    padding-top: 14%;
    padding-bottom: 14%;       
    border-radius: 300px;
    position: relative;
}
.cycle-data1{
    color:#eee; 
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);

}
.cycle-data2{
    color:#eee; 
    position: absolute;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);

}
.cycle-text{
    color:#222; 
    width: 28%;     
    text-align: center;
}
.cycle1{
    border: 2px solid #44c2b2;    
    background: rgba(68, 194, 178, 0.1);
}
.cycle2{
    border: 2px solid #8e9091;
    background: rgba(142, 144, 145, 0.1);
}
.cycle3{
    border: 2px solid #90724b;
    background: rgba(144, 114, 75, 0.1);
}

.cycle1 > h1{
    color : #44c2b2;         
}
.cycle1 > h3{
    color : #44c2b2;         
}
.cycle2 > h1{
    color : #8e9091;
}
.cycle2 > h3{
    color : #8e9091;
}
.cycle3 > h1{
    color : #90724b;
}
.cycle3 > h3{
    color : #90724b;
}

#iq-upcoming-movie{
    padding-top: 10%;
    max-height: 100vh;
    height: 100%;
}
.main-content-contaner{
    padding: 0 5%;
}

.text-icon{
    padding-right: 10px;
}
#iq-suggestede{
    padding-top: 10%;
    max-height: 100vh;
    height: 100%;   
    margin-bottom: 10%;
}
.main-content-contaner{
    padding: 0 5%;    
    margin : 0 auto;
}
.upcoming-contens{
    margin : 0 -10px;
}
.main-title4{
    color:#222;
    margin : 20px 0;
    text-align: left;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2 ;
    -webkit-box-orient: vertical;
}
.sub-content{
    text-align: left;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3 ;
    -webkit-box-orient: vertical;
}
.cycle-title4{
    color:#222; 
    text-align: center; 
    border: 1px solid #eee; 
    padding: 15px;
    background-color: #eee;
    min-height: 237px;
}
.cycle-title5{
    color:#222; 
    text-align: center; 
    border: 1px solid #eee; 
    padding: 15px;
    background-color: #eee;
}
.img-box {
    padding : 10px;
}

.cycle-text{
    color:#222; 
    width: 300px; 
    text-align: center;
}

#iq-topten{
    padding-top: 10%;
    max-height: 100vh;
    height: 100%;
}

.main-content-contaner{
    padding: 0 5%;    
    margin : 0 auto;
}
.main-title{
    color:#222;    
}
.main-title2{
    color:#222;
    margin : 20px 0;
}
.cycle-title2{
    color:#222; 
    text-align: center;     
    padding: 5%;
    margin : 10px;
    position: relative;    
}

@media (max-width: 576px){
    .cycle-title2{
        width: 100%;
    }
}


@media (max-width: 767px){
    .main-title {
        font-size: 1em;
    }
    .main-title2 {
        font-size: 1em;
    }
    .main-title4 {
        font-size: 1em;
    }
}
#contact_footer {
    display: inline-block;
    width: 100%;
    float: left;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background: #191919 !important;
    overflow: hidden;
    .footer-top {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}



.footer-standard .widget ul li {
    display: inline-block;
    padding-right: 30px;
}

.footer-link-title {
    margin-bottom: 20px;
    margin-top: 0;
}

footer.footer-one .footer-top .info-share li {
    margin-right: 12px;
    display: inline-block;
}

footer.footer-one .footer-top .info-share li a {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    margin-right: 0;
    color: #fff;
    font-size: 17px;
    border-radius: 50%;
    background: rgba(41,41,41,.76);
    transition: all .4s ease 0s;
}

footer.footer-one .footer-top .footer-standard .info-share {
    margin-bottom: 0;
}

footer.footer-one .footer-top .info-share {
    padding-left: 0;
}

@media (max-width: 991px){
    .main-container-fluid {
        padding-left: 30px;
        padding-right: 30px;
    }
    img.logo {
        width: 100px;
    }
    .navbar-collapse {
        position: fixed;
        top: 0;
        left: 0;
        padding-bottom: 15px;
        width: 260px;
        height: 100vh;
        background: #191919;
        padding-top: 40px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }
    .navbar-collapse.show {
        left: -15px;
        transition: left .3s ease-in;
        z-index: 999;
    }
    .navbar ul.navbar-nav {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 350px;
        display: inline-block;
        width: 100%;
    }
    .navbar ul {
        width: 100%;
        background: '#fff';
    }
    .navbar ul li {
        display: block;
    }
    .navbar ul li.menu-item a {
        line-height: normal;
        line-height: 10px;
        float: left;
        width: 100%;
        text-align: left;
        padding: 10px 30px;
    }
}

// html, body {
//     width: 100%;
//     height: 100%;
// }

// .scroll {
//     height: 100%;
// }

// #map {
//     height: 100%;
// }


// .radio-toolbar input[type="radio"] {
//     opacity: 0;
//     position: fixed;
//     width: 0;
//   }
//   .radio-toolbar label {
//     display: inline-block;
//     background-color: $gray-300;
//     cursor: pointer;
//     padding: 5px 10px;
//     font-size: 14px;
//     border-radius: 4px;
//   }
//   .radio-toolbar input[type="radio"]:checked + label {
//     background-color: $primary;
//     border: none;
//     color: $white;
//   }
  
//   #radio-title {
//     margin-bottom: 5px;
//   }
  
  